<template>
  <div><h1>General</h1></div>
</template>

<script>
export default {

}
</script>

<style>

</style>